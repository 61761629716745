
import { defineComponent } from 'vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import TmChip from '@/components/shared/TmChip.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import { campaignsEmailDestination } from '@/definitions/campaigns/data'
import EmailSimplePreview from '@/components/shared/templates/emailSimplePreview/EmailSimplePreview.vue'
import CampaignsEmailDetails from '@/components/views/campaigns/email/details/CampaignsEmailDetails.vue'

export default defineComponent({
  components: {
    CampaignsEmailDetails,
    EmailSimplePreview,
    TmChip,
    WhiteBlock,
    TmCountry,
    DetailsTable,
    DetailsTableItem,
  },
  setup() {
    const message = 'Hello John,\n\nDon\'t Miss Out on Summer\'s Hottest Deals!\n\nThe sun is shining, and so are our savings! Refresh your wardrobe with our summer essentials. Whether you\'re hitting the beach or lounging by the pool, we\'ve got everything you need to stay cool and stylish. Shop now and save 40% on shorts, tees, and more! But hurry, these deals are going, going, gone!'
    const subject = {
      title: 'Summer Offer 2024 🌴',
    }

    return {
      subject,
      message,
      campaignsEmailDestination,
    }
  },
})
